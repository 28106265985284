import React, { useRef, useState } from "react";

import {
	AudioPlayer,
	ButtomStoreWrapper,
	PlayPauseButton,
	ProgressContainer,
	QRImage,
	SeekBar,
	SongContainer,
	SongImage,
	SongInnerWrapper,
	SongPrompt,
	SongTitle,
	Timer,
} from "./style";
import PlayIcon from "../../assets/svgIcons/play";
import PauseIcon from "../../assets/svgIcons/pause";
import IosStoreIcon from "../../assets/svgIcons/iosStoreIcon";

// MusicPlayer, bir şarkı nesnesini prop olarak alan bir fonksiyonel bileşendir
const MusicPlayer = ({ song }) => {
	// audioRef, DOM'daki ses elementine bir referanstır
	const audioRef = useRef(null);
	// isPlaying, şarkının şu anda çalıp çalmadığını izleyen bir state değişkenidir
	const [isPlaying, setIsPlaying] = useState(false);
	// currentTime, şarkının mevcut zamanını izleyen bir state değişkenidir
	const [currentTime, setCurrentTime] = useState(0);
	// duration, şarkının toplam süresini izleyen bir state değişkenidir
	const [duration, setDuration] = useState(0);

	// togglePlayPause, mevcut duruma bağlı olarak şarkıyı çalar veya duraklatır
	const togglePlayPause = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	// handleTimeUpdate, şarkıdaki zaman güncellendiğinde currentTime state değişkenini güncelleyen bir fonksiyondur
	const handleTimeUpdate = () => {
		setCurrentTime(audioRef.current.currentTime);
	};

	// handleLoadedData, şarkı verisi yüklendiğinde şarkının süresini ayarlayan bir fonksiyondur
	const handleLoadedData = () => {
		setDuration(audioRef.current.duration);
	};

	// handleSeek, kullanıcı şarkıda farklı bir zamana geçtiğinde şarkının mevcut zamanını ve currentTime state değişkenini güncelleyen bir fonksiyondur
	const handleSeek = (e) => {
		audioRef.current.currentTime = e.target.value;
		setCurrentTime(e.target.value);
	};

	// formatTime, saniye cinsinden bir zaman alır ve mm:ss formatında bir dize döndürür
	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = ("0" + Math.floor(time % 60)).slice(-2);
		return `${minutes}:${seconds}`;
	};

	return (
		<SongContainer>
			<SongInnerWrapper>
				{/* Song yoksa QR resmi veriyoruz  */}
				{song ? (
					<>
						<SongImage src={song?.imageLargeUrl} alt={song?.title} />
						<SongTitle>{song?.title}</SongTitle>
						<SongPrompt>{song?.tags}</SongPrompt>
						<AudioPlayer className="audio-player"></AudioPlayer>
						<audio
							ref={audioRef}
							src={song?.audioUrl}
							onTimeUpdate={handleTimeUpdate}
							onLoadedData={handleLoadedData}
						/>
						<ProgressContainer className="progress-container">
							<Timer>{formatTime(currentTime)}</Timer>
							<SeekBar
								type="range"
								min="0"
								max={duration}
								value={currentTime}
								onChange={handleSeek}
								className="seek-bar"
							/>
							<Timer>{formatTime(duration)}</Timer>
						</ProgressContainer>
						<PlayPauseButton onClick={togglePlayPause} className="play-pause-button">
							{isPlaying ? <PauseIcon /> : <PlayIcon />}
						</PlayPauseButton>
					</>
				) : (
					<>
						<QRImage src="/images/musica-qr.jpg" alt="QR Code" />
						<SongPrompt>The song you were looking for could not be found</SongPrompt>
					</>
				)}
				<ButtomStoreWrapper>
					<a
						href="https://apps.apple.com/us/app/ai-music-generator-musica/id6502419500"
						target="_blank"
						rel="noreferrer"
					>
						<IosStoreIcon />
					</a>
				</ButtomStoreWrapper>
			</SongInnerWrapper>
		</SongContainer>
	);
};

export default MusicPlayer;

/* 
Burada müzik player componenti oluşturulmuştur.

Component içerisinde müzik player için gerekli olan butonlar, zamanlayıcılar ve müzik çalar yer almaktadır.
Fonksiyonlar: 
togglePlayPause: Müziği durdurup başlatır.
handleTimeUpdate: Müziğin zamanını günceller.
handleLoadedData: Müziğin yüklenme durumunu kontrol eder.
handleSeek: Müziği ileri geri sarmayı sağlar.
formatTime: Zamanı dakika ve saniye cinsinden formatlar.
Component içerisinde müzik player için gerekli olan butonlar, zamanlayıcılar ve müzik çalar yer almaktadır.

*/
