import styled from "styled-components";

export const LyricsContainer = styled.div`
	width: 300px;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: 20px;
	padding: 15px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
`;

export const LyricsText = styled.p`
	font-size: 14px;
	color: #fff;
	line-height: 1.5;
	text-align: left;
`;

export const HeaderText = styled.h2`
	color: ${(props) => props.theme.primaryText};
	font-size: 18px;
	font-family: "Poppins Bold", sans-serif;
	margin-bottom: 20px;
`;
