import React from "react";
import { BannerContainer, BannerIcon, BannerText, CloseButton, GetButton } from "./style";
import CloseIcon from "../../assets/svgIcons/closeIcon";

const Banner = ({ setIsBannerOpen }) => {
	return (
		<BannerContainer>
			<CloseButton onClick={() => setIsBannerOpen(false)}>
				<CloseIcon />
			</CloseButton>
			<BannerIcon src="/AppIcon.png" alt="Musica Icon" />
			<BannerText>
				<h1>AI Music Generator - Musica</h1>
				<p>Turn your ideas into songs</p>
			</BannerText>
			<GetButton href="https://apps.apple.com/us/app/ai-music-generator-musica/id6502419500" target="_blank">
				GET
			</GetButton>
		</BannerContainer>
	);
};

export default Banner;
