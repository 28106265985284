import * as React from "react"
const CloseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#979797"
      d="M.316.376A1.33 1.33 0 0 1 .682.102.891.891 0 0 1 1.15 0c.197 0 .299.021.454.088.182.08.559.446 7.396 7.277l3.595-3.593C15.837.534 16.213.17 16.392.088 16.55.02 16.653 0 16.85 0a.934.934 0 0 1 .468.102 1.295 1.295 0 0 1 .622.671.97.97 0 0 1 .057.44c-.01.148-.05.316-.095.422-.064.147-.65.752-7.267 7.365l3.609 3.61c3.492 3.495 3.612 3.622 3.69 3.826.06.168.073.263.06.457-.008.147-.046.309-.089.404-.042.088-.14.235-.225.327a1.128 1.128 0 0 1-.373.274.917.917 0 0 1-.464.102.967.967 0 0 1-.447-.088c-.182-.08-.559-.446-7.396-7.277l-3.598 3.593c-3.24 3.238-3.616 3.603-3.798 3.684a.985.985 0 0 1-.458.088.93.93 0 0 1-.464-.102 1.294 1.294 0 0 1-.622-.671.97.97 0 0 1-.057-.44c.01-.147.05-.316.095-.422.064-.147.65-.752 7.267-7.365L3.756 5.39C.264 1.894.144 1.767.066 1.563a.978.978 0 0 1-.063-.457C.013.97.053.791.088.703.126.615.228.468.316.376Z"
    />
  </svg>
)
export default CloseIcon
