import React, { useEffect, useState } from "react";
import { HeaderText, LyricsContainer, LyricsText } from "./style";

const Lyrics = ({ song }) => {
	const [formattedLyrics, setFormattedLyrics] = useState("");

	useEffect(() => {
		let stanzas = song?.prompt?.split(".").map((stanza) => stanza.trim());
		let result = [];

		for (let i = 0; i < stanzas.length; i++) {
			let lines = stanzas[i].split(",").map((line) => line.trim());
			result.push(lines.join("<br />"));
		}

		let finalResult = result.join("<br /><br />");
		setFormattedLyrics(finalResult);
	}, []);

	return (
		<LyricsContainer>
			<HeaderText>Lyrics</HeaderText>
			<LyricsText dangerouslySetInnerHTML={{ __html: formattedLyrics }} />
		</LyricsContainer>
	);
};

export default Lyrics;

/*
Burada Lyrics componentini oluşturduk.
useState hook'u ile formattedLyrics state'ini oluşturduk.
useEffect hook'u ile şarkı verilerinden lyrics'i alıp formatlıyoruz.
Şarkı verilerinden lyrics'i alıp . ile ayırıp her birini temizliyoruz.
Sonuç dizisine her birinin arasına <br /> ekliyoruz.
Sonuç dizisini birleştirip finalResult'a atıyoruz.
formattedLyrics state'ine finalResult'u atıyoruz.
LyricsContainer, HeaderText ve LyricsText componentlerini oluşturduk.
HeaderText ile Lyrics yazısını yazdık.
LyricsText ile formattedLyrics'i yazdık.
dangerouslySetInnerHTML ile html taglerini yazdık.

*/
