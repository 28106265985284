import styled from "styled-components";

export const SongContainer = styled.div`
	min-width: 500px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: 20px;
	padding: 15px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	@media (max-width: 768px) {
		box-shadow: unset;
		min-width: 90%;
	}
`;

export const SongInnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "Poppins Bold", sans-serif;
	justify-content: center;
	gap: 20px;
	width: 100%;
`;

export const SongTitle = styled.h1`
	font-size: 24px;
	text-align: center;
	color: ${(props) => props.theme.primaryText};
	@media (max-width: 768px) {
		font-size: 16px;
	}
`;

export const SongPrompt = styled.p`
	text-align: center;
	color: ${(props) => props.theme.primaryText};
	line-height: 32px;
	font-size: 16px;
	width: 80%;
	color: ${(props) => props.theme.primaryText};
	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

export const SongImage = styled.img`
	width: 300px;
	height: 300px;
	border-radius: 20px;
	@media (max-width: 768px) {
		width: 250px;
		height: 250px;
	}
`;

export const AudioPlayer = styled.div`
	width: 100%;
`;

export const ProgressContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	height: 30px;
	width: 80%;
`;

export const Timer = styled.span`
	color: ${(props) => props.theme.primaryText};
	font-size: 16px;
	text-align: center;
`;

export const SeekBar = styled.input.attrs({ type: "range" })`
	flex: 1;
	margin: 0 10px;
	-webkit-appearance: none;
	appearance: none;
	height: 5px;
	background: ${(props) => props.theme.mainColorDark};
	outline: none;
	opacity: 0.7;
	transition: opacity 0.2s;

	&:hover {
		opacity: 1;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background: ${(props) => props.theme.mainColorDark};
		cursor: pointer;
	}

	&::-moz-range-thumb {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background: ${(props) => props.theme.mainColorDark};
		cursor: pointer;
	}
`;

export const PlayPauseButton = styled.button`
	background-color: ${(props) => props.theme.mainColorDark};
	border: none;
	color: white;
	padding: 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	&:hover {
		background-color: ${(props) => props.theme.mainColor};
	}
	svg {
		fill: #fff;
	}
`;

export const ButtomStoreWrapper = styled.div`
	display: flex;
	gap: 20px;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

export const QRImage = styled.img`
	width: 100%;
	max-width: 300px;
`;
