import styled from "styled-components";

export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	height: 100px;
	color: ${(props) => props.theme.mainColor};
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-family: "Poppins Bold", sans-serif;
`;
