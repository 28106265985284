import styled from "styled-components";

export const BannerContainer = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;
	width: 400px;
	height: 90px;
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	border-radius: 20px;

	@media (max-width: 768px) {
		position: sticky;
		width: 100%;
		border-radius: unset;
		left: 0;
		top: 0;
	}
`;

export const CloseButton = styled.button`
	background: none;
	border: none;
	margin-left: 10px;
	cursor: pointer;
`;

export const BannerIcon = styled.img`
	width: 50px;
	height: 50px;
	margin: 0 10px;
	border-radius: 8px;
`;

export const BannerText = styled.div`
	flex-grow: 1;

	h1 {
		margin: 0;
		font-size: 15px;
		font-weight: bold;
	}

	p {
		margin: 0;
		font-size: 12px;
		color: #666;
	}
`;

export const GetButton = styled.a`
	background: ${(props) => props.theme.darkText};
	text-decoration: none;
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	line-height: 136.702%;
	margin: 0;
	border: none;
	padding: 5px 15px;
	border-radius: 15px;
	margin-right: 5px;
`;
