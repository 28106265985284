import { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import Lyrics from "../../components/lyrics";
import MusicPlayer from "../../components/musicPlayer";
import Header from "../../components/header/index.js";
import Banner from "../../components/banner/index.js";

import { LyricsWrapper, MusicAndLyricsWrapper, MusicWrapper } from "./style";

const Home = () => {
	// song state'ini oluşturduk
	const [song, setSong] = useState(null);
	// app banner'ını açık veya kapalı yapacak state'i oluşturduk
	const [isBannerOpen, setIsBannerOpen] = useState(true);
	// url'deki parametreleri alıyoruz
	const [searchParams] = useSearchParams();
	// songId'yi aldık
	const songId = searchParams.get("songId");

	useEffect(() => {
		const fetchSongData = async () => {
			try {
				const response = await axios.get(`https://app.musicaai.net/api/v1/social/${songId}`);
				if (response.data.status) {
					setSong(response.data.data);
					// setSong(response.data);
				}
			} catch (error) {
				console.error("Error fetching the song data", error);
			}
		};
		// url'den song id'sini alıp şarkı verilerini çekiyoruz
		// eğer songId gelmezse otomatik QR Resmi gözükecek
		if (songId) {
			fetchSongData();
		}
	}, [songId]);

	return (
		<>
			{isBannerOpen && <Banner setIsBannerOpen={setIsBannerOpen} />}
			<Header />
			{/* HTML elemanlarına geçilen propslar componentlerin üzerinde stil
			 kontrolü sağlar song'u yolladık. Öbür tarafta şarkı var mı yok mu kontrolü yapılıyor
			 ve ona göre componentlerin style'ı değişiyor.
			*/}
			<MusicAndLyricsWrapper song={song?.id}>
				<MusicWrapper song={song?.id}>
					<MusicPlayer song={song} />
				</MusicWrapper>
				{/* Şarkı yoksa lyrics componentini ekrandan kaldırıyoruz */}
				{song && (
					<LyricsWrapper>
						<Lyrics song={song} />
					</LyricsWrapper>
				)}
			</MusicAndLyricsWrapper>
		</>
	);
};

export default Home;

/* 
Burada Home componentini oluşturduk.
useState hook'u ile song state'ini oluşturduk.
useSearchParams hook'u ile url'deki parametreleri alıyoruz.
useEffect hook'u ile songId değiştiğinde yeni şarkı verilerini çekiyoruz.
fetchSongData fonksiyonu ile şarkı verilerini çekiyoruz.
setSong ile şarkı verilerini state'e atıyoruz.
Banner componentini açık olup olmadığını belirleyen state'i oluşturduk.
Header componentini ekledik.
MusicAndLyricsWrapper componentini oluşturduk.
MusicWrapper ve LyricsWrapper componentlerini oluşturduk.
MusicPlayer ve Lyrics componentlerini ekledik.

*/
