import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { theme } from "./utils/theme.js";
import Home from "./pages/home/index.js";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<Router>
					<Routes>
						<Route path="/:songId" element={<Home />} />
						<Route path="*" element={<Home />} />
					</Routes>
				</Router>
			</div>
		</ThemeProvider>
	);
}

export default App;

/* 
Burası root componentimizdir.
Burada styled-components'ın ThemeProvider'ını kullanarak tema sağlarız.
Ayrıca react-router-dom'un BrowserRouter ve Routes componentlerini kullanarak sayfalar arası geçiş yaparız.
Routes componenti içerisinde Route componentleri ile sayfalarımızı yönlendiririz.

Şuan için sadece Home componentini yönlendiriyoruz.
path="/:songId" ile sayfa url'sindeki songId parametresine göre Home componentini yönlendiriyoruz.
path="*" ile tüm diğer url'lerde de Home componentini yönlendiriyoruz.
Duruma göre pathName verip yeni componentler yapılabilir
*/
