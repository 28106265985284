import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MusicAndLyricsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	min-height: 100dvh;

	@media (max-width: 768px) {
		flex-direction: column;
		${({song}) =>
			!song &&
			css`
				justify-content: start;
			`}
	}
`;

export const MusicWrapper = styled.div`
	width: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	animation: 1s ${fadeIn} ease-in;
	margin-top: 100px;
	// song yoksa MusicWrapper itemini ortalar
	${({song}) =>
		!song &&
		css`
			justify-content: center;
		`}

	@media (max-width: 768px) {
		width: 90%;
		margin-top: unset;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
	}
`;

export const LyricsWrapper = styled.div`
	width: 50%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start; // And here
	padding-left: 120px;
	animation: 1.2s ${fadeIn} ease-in;
	margin-top: 100px;
	@media (max-width: 768px) {
		padding: unset;
		width: 90%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 45px;
		margin-top: unset;
		margin: 0 auto;
	}
`;
